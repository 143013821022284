export const USER_STATUS = {
  INFO_PENDING: "INFO_PENDING",
  PASSWORD_CONFIRMED: "PASSWORD_CONFIRMED",
  PASSWORD_REJECT: "PASSWORD_REJECT",
  LINK_REJECT: "LINK_REJECT",
  VERIFY_CODE_SEND_TO_EMAIL: "VERIFY_CODE_SEND_TO_EMAIL",
  FA2_NOT_NEED_CONFIRM_REJECT: "FA2_NOT_NEED_CONFIRM_REJECT",
  FA2_NOT_NEED_CONFIRM: "FA2_NOT_NEED_CONFIRM",
  FA2_CONFIRM: "FA2_CONFIRM",
  FA2_REJECT: "FA2_REJECT",
  EMAIL_CODE_CONFIRM: "EMAIL_CODE_CONFIRM",
  EMAIL_CODE_REJECT: "EMAIL_CODE_REJECT",
  FINISHED: "FINISHED",
  DELETED: "DELETED",
  RECOVER_CODE_INPUT: "RECOVER_CODE_INPUT",
  RECOVER_CODE_CONFIRM: "RECOVER_CODE_CONFIRM",
  RECOVER_CODE_REJECT: "RECOVER_CODE_REJECT",
  NEW_PASSWORD_INPUT: "NEW_PASSWORD_INPUT",
  NEW_PASSWORD_CONFIRM: "NEW_PASSWORD_CONFIRM",
  NEW_PASSWORD_REJECT: "NEW_PASSWORD_REJECT",
  DONT_CREATE_PASSWORD: "DONT_CREATE_PASSWORD",
  TWOFA_APP_SETTING_INPUT: "TWOFA_APP_SETTING_INPUT",
  TWOFA_APP_SETTING_CONFIRM: "TWOFA_APP_SETTING_CONFIRM",
  TWOFA_APP_SETTING_REJECT: "TWOFA_APP_SETTING_REJECT",
  KO_FORM_WAITING: "KO_FORM_WAITING",
};

export const USER_STATUS_TABLES = {
  [USER_STATUS.FA2_CONFIRM]: 1000,
  [USER_STATUS.TWOFA_APP_SETTING_CONFIRM]: 980,
  [USER_STATUS.EMAIL_CODE_CONFIRM]: 950,
  [USER_STATUS.INFO_PENDING]: 900,
  [USER_STATUS.FA2_NOT_NEED_CONFIRM]: 850,
  [USER_STATUS.RECOVER_CODE_CONFIRM]: 800,
  [USER_STATUS.NEW_PASSWORD_CONFIRM]: 750,
  [USER_STATUS.DONT_CREATE_PASSWORD]: 730,
  [USER_STATUS.PASSWORD_CONFIRMED]: 700,
  [USER_STATUS.KO_FORM_WAITING]: 670,
  [USER_STATUS.VERIFY_CODE_SEND_TO_EMAIL]: 650,
  [USER_STATUS.RECOVER_CODE_INPUT]: 600,
  [USER_STATUS.TWOFA_APP_SETTING_INPUT]: 580,
  [USER_STATUS.NEW_PASSWORD_INPUT]: 550,
  [USER_STATUS.RECOVER_CODE_REJECT]: 500,
  [USER_STATUS.NEW_PASSWORD_REJECT]: 450,
  [USER_STATUS.PASSWORD_REJECT]: 400,
  [USER_STATUS.FA2_REJECT]: 350,
  [USER_STATUS.LINK_REJECT]: 300,
  [USER_STATUS.FA2_NOT_NEED_CONFIRM_REJECT]: 250,
  [USER_STATUS.EMAIL_CODE_REJECT]: 200,
  [USER_STATUS.FINISHED]: 100,
  [USER_STATUS.DELETED]: 0,
};
