import { initializeApp } from "firebase/app";
import { getDatabase, ref, get, child, update, remove } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyASVp8KXrXEYxaKf5J9Tr543y8QbINOAd4",
  authDomain: "qlkh-db-af21d.firebaseapp.com",
  databaseURL: "https://qlkh-db-af21d-default-rtdb.firebaseio.com",
  projectId: "qlkh-db-af21d",
  storageBucket: "qlkh-db-af21d.appspot.com",
  messagingSenderId: "767413291001",
  appId: "1:767413291001:web:d8d75680eafaa7ff881f28",
  measurementId: "G-T7BYB80MWN"
};

const firebase = initializeApp(firebaseConfig);
const firebaseDB = getDatabase(firebase);

const usersFirebaseRef = ref(firebaseDB, "users");

async function getUsers() {
  const dbRef = ref(getDatabase());
  const snapshotUser = await get(child(dbRef, `users`));
  if (snapshotUser.exists()) return snapshotUser.val();
  return {};
}

function updateUser({ sessionId, userInfo }) {
  update(ref(firebaseDB, "users/" + sessionId), userInfo);
}

function deleteUser({ sessionId }) {
  remove(ref(firebaseDB, "users/" + sessionId));
}

export { firebaseDB, usersFirebaseRef, getUsers, updateUser, deleteUser  };
